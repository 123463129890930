<template>
  <Layout>
    <div class="row my-1 position-relative">
      <div class="col-7">
        <div class="row">
          <img v-if="getCurrentIncident" class="col-2 my-auto" style="width: 50px"
               :src="require(`@/assets/images/incident/${getCurrentIncident.map_icon}`)"
               :alt="getCurrentIncident.name"
          />
          <div class="col my-auto">
            <PageHeader :title="getCurrentIncident ? getCurrentIncident.name : 'Command Board'"/>
          </div>
        </div>
      </div>
      <div class="col d-flex flex-row justify-content-center gap-2 z-3">
        <IncidentsSelector />
      </div>
    </div>
      <CommandBoardMap />
  </Layout>
</template>
<script>
import Layout from '@/router/layouts/main.vue';
import PageHeader from '@/components/page-header.vue';
import CommandBoardMap from '@/components/commandBoard/maps/commandBoardMap.vue';
import {
  incidentsComputed,
  authComputed,
  incidentsMethods,
  mapToolsMethods,
}
  from '@/state/helpers';
import IncidentsSelector from '@/components/commandBoard/selectors/incidentsSelector.vue';

export default {
  components: {
    IncidentsSelector,
    CommandBoardMap,
    Layout,
    PageHeader,
  },
  computed: {
    ...authComputed,
    ...incidentsComputed,
  },
  methods: {
    ...incidentsMethods,
    ...mapToolsMethods,
  },
};
</script>
