<template>
  <div
    class="bg-body shadow-sm rounded-2"
    ref="draggableContainer"
    id="draggable-container"
    @mouseover="mouseOver"
    @focus="mouseOver"
  >
    <div id="draggable-header" class="row h-auto" @mousedown="dragMouseDown">
      <slot name="header">{}</slot>
    </div>
    <div class="row">
      <slot name="main"></slot>
    </div>
    <div @mousedown="dragMouseResize">
      <i
        class="fas fa-up-right-and-down-left-from-center"
        id="draggable-footer"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sizes: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
      },
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
      },
    };
  },
  methods: {
    mouseOver(event) {
      event.preventDefault();
      document.onmouseover = this.disableMapScroll;
    },
    disableMapScroll() {
      this.$emit('disableScroll');
    },
    dragMouseResize(event) {
      event.preventDefault();
      // get the mouse cursor position at startup:
      this.sizes.clientX = event.clientX;
      this.sizes.clientY = event.clientY;
      document.onmousemove = this.elementResize;
      document.onmouseup = this.closeDragElement;
    },
    elementResize(event) {
      event.preventDefault();
      this.sizes.movementX = this.sizes.clientX - event.clientX;
      this.sizes.movementY = this.sizes.clientY - event.clientY;
      this.sizes.clientX = event.clientX;
      this.sizes.clientY = event.clientY;

      // set the element's new position:
      this.$refs.draggableContainer.style.width = `${this.$refs.draggableContainer.offsetWidth + (this.sizes.movementX * -1)}px`;
      this.$refs.draggableContainer.style.height = `${this.$refs.draggableContainer.offsetHeight + (this.sizes.movementY * -1)}px`;
    },
    dragMouseDown(event) {
      event.preventDefault();
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag(event) {
      event.preventDefault();
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      // set the element's new position:
      this.$refs.draggableContainer.style.top = `${this.$refs.draggableContainer.offsetTop - this.positions.movementY}px`;
      this.$refs.draggableContainer.style.left = `${this.$refs.draggableContainer.offsetLeft - this.positions.movementX}px`;
    },
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    },
  },
  mounted() {
    if (this.minWidth) {
      this.$refs.draggableContainer.style.minWidth = this.minWidth;
    }

    if (this.minHeight) {
      this.$refs.draggableContainer.style.minHeight = this.minHeight;
    }

    this.$refs.draggableContainer.style.top = `${this.$refs.draggableContainer.offsetTop - 500}px`;
    this.$refs.draggableContainer.style.left = `${this.$refs.draggableContainer.offsetLeft - 1000}px`;
  },
  props: {
    minWidth: {
      type: String,
      default: '300px',
      validator(value) {
        const matches = value.matchAll(/([0-9]{3}|[0-9]{4})/g);
        Object.keys(matches).forEach((index) => {
          console.log(matches[index]);
        });

        return true;
      },
    },
    minHeight: {
      type: String,
      default: '400px',
      validator(value) {
        const matches = value.matchAll(/([0-9]{3}|[0-9]{4})/g);
        Object.keys(matches).forEach((index) => {
          console.log(matches[index]);
        });

        return true;
      },
    },
  },
};
</script>

<style scoped>
#draggable-container {
  min-width: 500px;
  min-height: 500px;
  height: auto;
  position: absolute;
  z-index: 98;
  max-height: 50vh;
}
#draggable-header {
  padding-left: 12px;
  padding-right: 12px;
  z-index: 99;
  cursor: grab;
}
#draggable-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 4px 4px;
  z-index: 99;
  cursor: nwse-resize;
}
</style>
