<template>
  <div>
    <!-- Listing -->
    <div id="layer-container">
      <div>
        <b-button v-b-toggle.collapse-1 variant="primary">
          Common Layers&nbsp;&nbsp;
          <span class="when-opened">
            <i class="fa fa-chevron-down" aria-hidden="true"></i>
          </span>
          <span class="when-closed">
            <i class="fa fa-chevron-up" aria-hidden="true"></i>
          </span>
        </b-button>
        <b-collapse id="collapse-1" class="mt-2">
          <b-card>
            <div v-for="(layer, index) in getCommonLayers" :key="index"
                 class="row">
              <div class="col-md-12">
                <div class="layer-pane">
                  <b-form-checkbox :checked="layer.name === 'None'" :disabled="true"
                                   @change="updateSelectedLayers(layer)"
                  >

                    {{
                      layer.name
                    }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </b-card>
        </b-collapse>
      </div>

      <div id="layers-list-pane">
        <div v-for="(layer, index) in getLayers" :key="index"
                         class="row">
          <div class="col-md-12">
            <div class="layer-pane">
              <div role="button" v-b-tooltip.hover :title="layer.notes"
                   v-if="layer.notes !== null && layer.notes !== ''">
                <i class="fas fa-info-circle fa-lg text-info"></i>
              </div>
              <div v-else style="width:25px;">
                &nbsp;
              </div>

              <div v-if="layer.cluster_enabled === 1">
                <i class="fas fa-cubes fa-lg text-success"></i>
              </div>
              <div v-else style="width:30px;">
                &nbsp;
              </div>

              <div>
                <b-form-checkbox
                  :checked="getDisplayLayers.filter(
                  e => e.layer_id === layer.id && e.display === true
                  ).length > 0"
                  @change="updateSelectedLayers(layer)"
                  :disabled="layer.url !== null"
                >
                  {{
                    layer.name
                  }}
                </b-form-checkbox>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {
  layersComputed,
  layersMethods,
  authComputed,
  mapToolsMethods,
  landmarksMethods,

} from '@/state/helpers';

export default {
  data() {},
  computed: {
    ...layersComputed,
    ...authComputed,
  },
  methods: {
    ...layersMethods,
    ...mapToolsMethods,
    ...landmarksMethods,
    async updateSelectedLayers(layer) {
      const layer_display = await this.upsertDisplayLayer({
        payload: layer, user: JSON.parse(this.currentUser),
      });

      // reload/remove maptools and landmarks
      if (layer_display.data.layer_display.display === true) {
        await this.loadLayerMapTools({
          user: JSON.parse(this.currentUser), layer: layer_display.data.layer_display,
        });
        await this.loadLayerLandmarks({
          user: JSON.parse(this.currentUser), layer: layer_display.data.layer_display,
        });
      } else {
        await this.removeLayerMapTools({
          layer: layer_display.data.layer_display,
        });
        await this.removeLayerLandmarks({
          layer: layer_display.data.layer_display,
        });
      }
    },
  },
};
</script>
<style scoped>
.layer-pane {
  padding: 3px 5px;
  display: flex;
  justify-content: flex-start;
}
.layer-pane > div{
  padding: 5px;
}
#layer-container {
  padding: 10px;
  max-height: 400px;
  max-height: 400px;
  overflow-y: scroll !important;
  overflow-x: hidden;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
</style>
