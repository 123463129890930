<template>
  <div class="row">
    <div
      class="rounded-md col"
    >
      <LMap ref="map"
            :options="mapOptions"
            :class="getListenMapLocationStatus ? 'cursor-crosshair' : 'cursor-grab'"
            style="height: 79vh"
            @ready="init"
            v-model:zoom="zoom"
            @click="getMapClickLocation"
            :center="center"
      >
        <LTileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution="OpenStreetMap.org"/>
        <!-- Incident center marker -->
        <LMarker
          v-if="getCurrentIncident"
          :lat-lng="[
            getCurrentIncident.coordinates.points.latitude,
            getCurrentIncident.coordinates.points.longitude
          ]"
          >
          <LIcon :icon-url="require(`@/assets/images/incident/${getCurrentIncident.map_icon}`)" />
        </LMarker>
        <LMarker
          v-for="(marker, index) in markers"
          :key="index"
          :lat-lng="[marker.lat, marker.lng]"
          :attribution="marker.id.toString()"
          @click="(event) => markerEditing(event, marker)"
        >
          <LIcon
            :icon-url="
            require(`@/assets/images/${this.getFormattedAssetUrl(marker.icon)}`)"
          />
        </LMarker>
        <LMarker
          v-for="(landmark, index) in getCurrentLandmarks"
          :key="index"
          :lat-lng="[landmark.latitude, landmark.longitude]"
          @click="(event) => landmarkEditing(event, landmark)"
        >
          <LIcon
            :icon-url="
            require(
              `@/assets/images/landmarks/${this.getFormattedLandmarkAssetUrl(landmark.icon)}`
            )" />
        </LMarker>
        <LControl
          v-if="getCurrentIncident"
          position="bottomright"
        >
          <IncidentResource
            @mapFocus="updateMapCenter"
            @mouseOverWindow="disableMapScroll"
          />
        </LControl>
        <LControl
          v-if="getCurrentIncident"
          position="topleft"
        >
          <div class="d-flex flex-column justify-content-start">
            <Button
              tooltipPosition="right"
              customClasses="rounded-0 rounded-top rounded-right rounded-end"
              variant="secondary"
              :data="{ description: 'Command and Planning'}"
            >
              <template v-slot:content="data">
                <img src="@/assets/images/logo.svg" :alt="data.description" class="mx-2" />
              </template>
            </Button>
            <Button
              tooltipPosition="right"
              customClasses="rounded-0 rounded-bottom mt-n1 w-75 border-0 p-auto"
              @click="updateMapCenter({...getCurrentIncident.coordinates.points})"
              variant="secondary"
              :data="{
                description: 'Center on incident',
                icon: 'fas fa-arrows-to-circle',
              }"
            />
          </div>
        </LControl>
      </LMap>
    </div>
    <div class="col-md-4 col-xl-2 bg-light shadow-md" v-show="isEditing">
      <MapToolsForm
        @mapToolsUpdated="updateMapTools()"
        @landmarkUpdated="updateLandmarks()"
      />
    </div>
  </div>
</template>

<script>

import {
  LMap, LTileLayer, LMarker, LIcon,
  LControl,
} from '@vue-leaflet/vue-leaflet';
import Button from '@/components/widgets/button.vue';
import L from 'leaflet';
import 'leaflet-draw';
import MapToolsForm from '@/components/commandBoard/forms/mapToolsForm.vue';
import {
  mapToolsComputed, incidentsMethods, incidentsComputed, layersMethods, layersComputed,
  mapToolsMethods, authComputed, landmarksComputed, landmarksMethods, tasksComputed, tasksMethods,
} from '@/state/helpers';
import { showMessage } from '@/components/widgets/swalUtils';
import defaultExternalLayers from '@/components/commandBoard/maps/defaultExternalLayers';
import { tiledMapLayer } from 'esri-leaflet';
import {
  capitalizeFirstLetter, getGoogleLayer, getGoogleMapType, defaultGoogleLayerName,
} from '@/components/commandBoard/maps/googleMapsUtils';
import IncidentResource from '@/components/commandBoard/resourcesManagement/incidentResource.vue';
import DrawLandmark from '@/components/commandBoard/maps/drawLandmark';
import getDrawToolbarOptions from '@/components/commandBoard/utils/mapUtils';
import { toLatLng } from 'leaflet/src/geo/LatLng';

export default {
  components: {
    MapToolsForm,
    LMap,
    LTileLayer,
    LIcon,
    LMarker,
    LControl,
    IncidentResource,
    Button,
  },
  data() {
    return {
      cbLoaded: false,
      mapDrawersInitialized: false,
      object: null,
      tool: null,
      isEditing: false,
      zoom: 18,
      mapOptions: {
        attributionControl: false,
        zoomControl: false,
      },
      layers: [],
      drawnItems: null,
      drawControl: null,
      center: null,
      markers: [],
      landmarks: [],
      incident_id: 0,
    };
  },
  computed: {
    ...mapToolsComputed,
    ...landmarksComputed,
    ...incidentsComputed,
    ...layersComputed,
    ...authComputed,
    ...tasksComputed,
  },
  watch: {
    getCurrentMapTools: {
      handler(mapTools) {
        if (this.cbLoaded === true) {
          if (mapTools?.length) {
            this.initializeMapTools();
            this.isEditing = false;
          }
        }
      },
      deep: true,
    },
    getDisplayLayers: {
      async handler() {
        if (this.cbLoaded === true) {
          await this.initializeMapTools();
          this.isEditing = false;
        }
      },
      deep: true,
    },
  },
  created() {
    const { incidentId } = this.$route.params;
    this.incident_id = +incidentId;
  },
  methods: {
    ...landmarksMethods,
    ...mapToolsMethods,
    ...tasksMethods,
    ...layersMethods,
    ...incidentsMethods,
    async init() {
      if (this.getCurrentIncident === null
        || typeof this.getCurrentIncident === 'undefined'
        || this.getCurrentIncident?.id !== this.incident_id
        || typeof this.getCurrentIncident.coordinates === 'undefined') {
        await this.loadIncident({
          user: JSON.parse(this.currentUser),
          incident_id: this.incident_id,
        });
      }

      this.setIncident(this.getCurrentIncident);

      if (this.getLayers.length === 0) {
        await this.loadLayers({ user: JSON.parse(this.currentUser) });
      }

      await this.updateMapTools();

      await this.updateLandmarks();

      await this.initializeMapTools();

      this.cbLoaded = true;
    },
    async setIncident(incident) {
      if (incident?.coordinates) {
        this.isEditing = false;
        this.$refs.map.leafletObject.setView(
          [
            incident.coordinates.points.latitude,
            incident.coordinates.points.longitude,
          ],
        );
        await this.updateMapCenter(incident.coordinates.points);
      }

      if (!this.mapDrawersInitialized) {
        this.addDrawControls();
        this.mapDrawersInitialized = true;
      }
    },
    getMapClickLocation(e) {
      if (this.getListenMapLocationStatus && e?.latlng !== undefined) {
        this.setCurrentTask({
          task: {
            ...this.getCurrentTask,
            latitude: e.latlng.lat.toString(),
            longitude: e.latlng.lng.toString(),
          },
        });
        this.switchClickLocationStatus({ status: false });
      }
    },
    updateMapCenter(coordinates) {
      try {
        this.$refs.map.leafletObject.setView(
          [
            coordinates.latitude,
            coordinates.longitude,
          ],
        );
      } catch (e) {
        console.log('Unable to center.', e.message);
      }
    },
    disableMapScroll() {
      this.mapOptions.scrollWheelZoom = !this.mapOptions.scrollWheelZoom;
    },
    markerEditing(event, marker) {
      this.setEditingMapTools({ data: marker, tool: event });
      this.isEditing = true;
    },
    landmarkEditing(event, landmark) {
      this.setEditingMapTools(
        {
          data:
            {
              ...landmark,
              type: 'landmark',
              lat: event.latlng.lat,
              lng: event.latlng.lng,
            },
          tool: event,
        },
      );
      this.isEditing = true;
    },
    getFormattedAssetUrl(url) {
      if (url.match('/img/maptools/')) {
        return url.toString().replace('/img/maptools', 'maptools');
      }
      return `maptools/${url}`;
    },
    getFormattedLandmarkAssetUrl(url) {
      return url.toString().replace('/img/landmarks', 'landmarks');
    },
    async clearLayers() {
      const { _layers } = this.$refs.map.leafletObject;

      // TODO: make differential removal of mapTools
      await Object.keys(_layers).forEach((key) => {
        if (_layers[key]) {
          if (
            parseInt(_layers[key].options?.attribution, 10)
          ) {
            try {
              this.$refs.map.leafletObject.removeLayer(_layers[key]);
            } catch (e) {
              console.log(e);
            }
          }
        }
      });
    },
    async initializeMapTools() {
      await this.clearLayers();

      this.markers = [];

      const toolsIncident = this.getCurrentMapTools
        .filter((maptool) => maptool.layer_id === null);

      if (toolsIncident) {
        Object.keys(toolsIncident).forEach((index) => {
          this.drawMapToolOnMap(toolsIncident[index]);
        });
      }

      const tools = await this.filterMapTools({
        layers: this.getDisplayLayers.filter((display_layer) => display_layer.display === true),
      });

      if (tools) {
        Object.keys(tools).forEach((index) => {
          this.drawMapToolOnMap(tools[index]);
        });
      }
    },
    async drawMapToolOnMap(mapTool) {
      const mapToolsObjects = [];
      const { type } = mapTool;
      const location = toLatLng(mapTool.lat, mapTool.lng);

      switch (type) {
        case 'marker':
          this.markers.push(mapTool);
          break;
        case 'polyline':
          mapToolsObjects.push(L.polyline(this.convertPoints(mapTool.line_points), {
            color: mapTool.color,
            attribution: mapTool.id,
          }).on('click', (e) => {
            this.setEditingMapTools({ tool: e, data: mapTool });
            e.target.editing.enable();
            this.isEditing = true;
          }));
          break;

        case 'polygon':
          mapToolsObjects.push(L.polygon(this.convertPoints(mapTool.line_points), {
            color: mapTool.color,
            attribution: mapTool.id,
          }).on('click', (e) => {
            this.setEditingMapTools({ tool: e, data: mapTool });
            e.target.editing.enable();
            this.isEditing = true;
          }));
          break;

        case 'rectangle':
          mapToolsObjects.push(L.rectangle(this.convertPoints(mapTool.line_points), {
            color: mapTool.color,
            attribution: mapTool.id,
          }).on('click', (e) => {
            this.setEditingMapTools({ tool: e, data: mapTool });
            e.target.editing.enable();
            this.isEditing = true;
          }));
          break;

        case 'circle':
          mapToolsObjects.push(L.circle(
            location,
            {
              fill: true,
              'fill-opacity': 0.4,
              'fill-color': mapTool.color,
              radius: mapTool.radius * 1609.34,
              color: mapTool.color,
              attribution: mapTool.id,
            },
          ).on('click', (e) => {
            this.setEditingMapTools({ tool: e, data: mapTool });
            // TODO: enable editing only when able to resize and update the form
            // For now, only on the form is possible to change the radius
            // e.target.editing.enable();
            this.isEditing = true;
          }));
          break;
        default:
          break;
      }

      Object.keys(mapToolsObjects).forEach((index) => {
        mapToolsObjects[index].addTo(this.$refs.map.leafletObject);
      });
    },
    convertPoints(address) {
      const points = [];
      if (address) {
        Object.keys(address).forEach((index) => {
          try {
            points.push([address[index].x, address[index].y]);
          } catch (e) {
            showMessage({
              title: 'Problem loading the map tools',
              message: `We've found some issues while loading the map tools of this incident.${e}`,
              icon: 'error',
            });
          }
        });
      }
      return points;
    },
    async updateMapTools() {
      this.isEditing = false;
      await this.loadMapTools({
        user: JSON.parse(this.currentUser),
        incident: this.getCurrentIncident,
        invalidate: true,
        layers: this.getDisplayLayers,
      });
    },
    async updateLandmarks() {
      this.isEditing = false;
      await this.loadLandmarks({
        user: JSON.parse(this.currentUser),
        layers: this.getDisplayLayers,
      });
    },
    async addDrawControls() {
      Object.keys(defaultExternalLayers).forEach((key) => {
        const label = defaultExternalLayers[key].name;
        const { url, maxZoom } = defaultExternalLayers[key];
        let baseLayer;

        if (url.match('openstreetmap')) {
          baseLayer = L.tileLayer(url, {
            maxZoom,
            maxNativeZoom: 18,
          });
        }

        if (url.match('weather')) {
          baseLayer = L.tileLayer(url, {
            attribution: 'Weather data',
          });
        }

        if (url.match('arcgisonline')) {
          baseLayer = tiledMapLayer({
            url,
            maxZoom,
          });
        }

        this.layers[label] = baseLayer;
      });

      Object.keys(defaultGoogleLayerName).forEach((key) => {
        const mapType = getGoogleMapType(defaultGoogleLayerName[key]);
        this.layers[capitalizeFirstLetter(mapType)] = getGoogleLayer(mapType);
      });

      L.control.layers(this.layers, [], {
        position: 'bottomleft',
      }).addTo(this.$refs.map.leafletObject);

      const drawnItems = new L.FeatureGroup();

      const drawLandmark = new DrawLandmark(this.$refs.map.leafletObject);
      drawLandmark.modifyModeHandlers();

      this.drawControl = new L.Control.Draw(getDrawToolbarOptions);

      this.$refs.map.leafletObject.addLayer(drawnItems);
      this.$refs.map.leafletObject.addControl(this.drawControl);
      this.$refs.map.leafletObject.on(L.Draw.Event.CREATED, (e) => {
        if (
          e.layerType !== 'marker'
          && e.layerType !== 'landmark'
          && e.layerType !== 'circle'
        ) {
          this.setEditingMapTools(
            {
              tool: { ...e },
              data: {
                type: e.layerType,
              },
            },
          );
          // TODO: enable editing during creation causes vuex exception
          // e.layer.editing.enable();
        } else {
          this.setEditingMapTools(
            {
              tool: { ...e },
              data: {
                date: new Date().toISOString().slice(0, 10),
                type: e.layerType,
                lat: e.layer.getLatLng().lat,
                lng: e.layer.getLatLng().lng,
              },
            },
          );
        }
        e.layer.options.attribution = '01';
        drawnItems.addLayer(e.layer);
        this.isEditing = true;
      });

      if (this.getCurrentIncident?.coordinates) {
        this.$refs.map.leafletObject.setView(
          [
            this.getCurrentIncident.coordinates.points.latitude,
            this.getCurrentIncident.coordinates.points.longitude,
          ],
        );
      }
    },
  },
};

</script>
<style>

.leaflet-draw-toolbar .leaflet-draw-draw-landmark {
  background-image: url("/img/landmark.b6642691.svg");
  background-size: 13px 13px;
  background-color: #FFF;
}

</style>
